@import '~@holokit/core/src/common/styles/_colors.scss';

.ReleaseFlag {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orgItem {
  width: fit-content;
  color: $color-gray-00;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-white;
  transition: border-bottom 0.1s ease-in;
  cursor: pointer;
  &:hover {
    color: initial;
    text-decoration: none;
    border-bottom: 1px solid $color-gray-00;
  }
}

.orgItemsLink:hover {
  color: initial;
  text-decoration: none;
}

.title {
  margin-top: 0;
}

.orgContainer {
  list-style: none;
  padding: 0;
}

.outerContainer {
  width: 60%;
  max-width: 850px;
}

.panel {
  margin-top: 16px;
}
