.container {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  position: relative;
}

.childrenContainer {
  flex-grow: 1;
  overflow-y: auto;
}

.contentContainer {
  flex-grow: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
