@import '~@holokit/core/src/common/styles/_colors.scss';

.releaseFlags {
  $padding: 100px;
  margin: 0 $padding 0 $padding;
}

.container {
  display: flex;
  justify-content: space-around;
}

.list {
  list-style: none;
  padding: 0;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: space-around;
  margin-bottom: 20px;
}

.name {
  color: $color-gray-00;
  margin-bottom: 10px;
}

.link {
  color: $color-gray-00;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-white;
  transition: border-bottom 0.1s ease-in;
  &:hover {
    color: $color-gray-00;
    text-decoration: none;
    border-bottom: 1px solid $color-gray-00;
    cursor: pointer;
  }
}
