@import '~@holokit/core/src/common/styles/_colors.scss';

.modalContainer {
  padding: 16px;
}

.panel {
  margin-bottom: 0 !important;
}

.footer {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.stacktrace {
  background: $color-gray-40;
  padding: 8px;
  margin-top: 12px;
  color: white;
}
