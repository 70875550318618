@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';
@import './sharedStyles.module.scss';

.customPlansContainer {
  display: flex;
}

.searchForm {
  display: flex;
  flex-direction: column;
}

.searchFormHeader {
  font-weight: normal;
}

.input_group {
  width: 300px;
}

.headerCell {
  @extend .Table__cell--header;
  text-align: center;
  position: sticky;
  top: 0;
}

.searchedListContainer {
  display: flex;
  flex-grow: 1;
}

.button {
  align-self: flex-end;
  margin-top: 25px;
}

.editHeader {
  @extend .headerCell;
  width: 35px;
}
