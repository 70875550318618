.container {
  padding: 0 18px;
}

.message {
  padding: 40px;
  text-align: center;
}

.messageError {
  composes: message;
  color: #c23754;
}

.form {
  margin-left: auto;
}

.user {
  cursor: pointer;
}
