@import '~scss/containers.scss';

.container {
  @extend %main-content-container;
  padding-bottom: 28px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}

.createButton {
  float: right;
  margin-bottom: 28px;
}

.headerButton {
  margin-left: auto;
}
