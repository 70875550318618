@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';

.callout {
  b {
    font-weight: 500;
  }
}

.package_form_container {
  align-items: center;
  display: flex;
  padding-bottom: 24px;

  &.package_form_fee_container {
    align-items: start;
    margin-top: 20px; // $dds-space-5x;
  }
}

.package_form_field_label {
  font-weight: 500;
  margin-right: 6px;
  width: 180px;
}

.selected_package_dropdown {
  flex: 1;
  max-width: 370px;
}

.package_callout {
  border: 2px solid var(--dds-color-palette-teal-30);

  &__title {
    font-weight: bold;
  }
}

.fee_row {
  display: flex;

  &__label {
    width: 5rem;
  }

  &__amount {
    align-items: baseline;
    display: flex;

    &::before {
      content: '$';
      margin-right: 4px; // $dds-space-1x; (this doesn't work yet)
    }

    &--default {
      align-items: center;
      width: 3.1rem;
      justify-content: space-between;
    }

    &--disabled {
      color: #6b738c; // $dds-color-text-diminished-ondefault; (this doesn't work yet)
    }
  }

  &__custom_amount {
    display: flex;
    flex-direction: column;

    &__helptext {
      color: #6b738c; // $dds-color-text-diminished-ondefault; (this doesn't work yet)
      margin-top: 4px; // $dds-space-1x; (this doesn't work yet)
    }
  }
}

.gating_flag_container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 100%;
  column-gap: 0; // So the borders line up
  margin-top: 24px;
}

.package_heading {
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0;
}

.package_description {
  font-weight: 400;
  margin-bottom: 12px;
  margin-top: 0;
}

.flag_row {
  align-items: start;
  display: flex;
  gap: 8px;
  padding: 5px 8px 4px 0;

  &__checkbox {
    font-family: inherit;
  }

  &__label {
    cursor: pointer;
  }

  &--disabled {
    color: $color-gray-80;

    .flag_row__label {
      cursor: not-allowed;
    }
  }
}

.preflight_radio {
  padding: 0px;
  margin: 0px;
}

.preflight_custom_text [class*='dds-text-input'] {
  width: 50px;
}

.response_callout {
  padding-right: 8px;

  &:first-child {
    margin-top: 24px;
  }

  & + .response_callout {
    margin-top: 4px;
  }
}

.preflight_custom_text_days {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  // justify-content: center;
  justify-content: flex-start;
  width: 90px;
}
