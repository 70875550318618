.billing_summary_container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  column-gap: 10px;
}

.balance_section {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.balance_buttons {
  display: flex;
  margin-top: 10px;
}

.promo_section {
  display: flex;
  flex-direction: column;
}

.promo_text {
  span {
    background-color: #f7f8fb;
    display: block;
    font-size: 16px;
    letter-spacing: 0px;
    padding: 0.5em;
  }
}

.promo_button {
  margin-top: 15px;
}

.left_column {
  grid-column: 1 / 2;
}

.right_column {
  grid-column: 2 / 3;

  th,
  td {
    padding: 10px;
    font-size: 16px;
    font-weight: normal;
    height: 20px;
    letter-spacing: 0px;
    line-height: 20px;
    vertical-align: middle;
  }

  th {
    color: #040815;
    text-align: left;
  }

  td {
    color: rgb(84, 93, 121);
    text-align: right;
  }
}
