@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';
@import '../../sharedStyles.module.scss';

.TableCell {
  @extend .Table__cell;
  @extend .Table__cell--no-border-right;
}

.TableRow {
  @extend .Table__row;
  height: 31px;
}

.ListSmall {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ZoneTableSubTable {
  @extend .TableSubTable;

  td.ZoneTableCell {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
