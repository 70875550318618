@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';

.formContainer {
  display: flex;
}

.inputGroup {
  display: flex;
  margin-bottom: 8px;
}

.inputContainer {
  min-width: 350px;
}

.input_label {
  display: flex;
  align-items: center;
  margin-right: 8px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  color: rgb(4, 8, 21);
  width: 84px;
}

.input_field {
  flex: 2;
}

.resultsTable {
  padding-top: 24px;
  width: 100%;
}

.viewOrgButton {
  text-align: right;
}

.listContainer {
  max-height: 460px;
  overflow: auto;
  padding-left: 72px;
}

.viewMoreButton {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}

.buttonClassname {
  align-self: flex-end;
}

.form {
  display: flex;
  flex-direction: column;
}

.caption {
  @extend .Font__caption--20;
}

.userDetails {
  padding-top: 16px;
}

.orgCell {
  vertical-align: middle;
}

.orgCell,
.viewOrgButton {
  padding-right: 8px;
}
