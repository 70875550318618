.plans_table {
  table {
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    padding: 10px 15px;
    text-align: left;
    border-bottom: 1px solid #f7f8fb;
  }

  th {
    font-size: 10px;
    font-weight: 500;
    height: 15px;
    letter-spacing: 0.8px;
    line-height: 14px;
    text-transform: uppercase;
    background-color: #f7f8fb;
    color: rgb(107, 115, 140);
    height: 40px;
  }
}

.no_plans_result {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background: #f8f9fb;
  font-weight: 200;
  font-size: 18px;
  color: #091c45;
  text-align: left;
  line-height: 23px;
}

.no_plans_text {
  margin-left: 16px;
}
