@import '~@holokit/core/src/common/styles/_colors.scss';

.userItem {
  padding: 8px;
  min-width: 250px;

  &:hover {
    background: $color-gray-120;
  }
}

.panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 600px;
  overflow: auto;
}
