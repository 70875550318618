@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';

.organizations__container {
  margin: 0 2em 0 2em;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
}

.organization_name_header {
  @extend .Font__headline;
  @extend .Font__headline--30;

  margin-top: 36px;
  margin-bottom: 20px;
}
