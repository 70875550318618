@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';

.overlay {
  background-image: linear-gradient(225deg, #bc24ff 0%, #04a7ff 50%, #00d5c2 100%);
  position: absolute;
  opacity: 0.65;
  height: 100%;
  width: 100%;
}

.loginForm {
  width: 80%;
}

.header {
  @extend .Font__headline--50;
  height: 80px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  padding-left: 40px;
}

.panelBody {
  @extend .Font__headline--40;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon {
  width: 200px;
}

.image {
  position: absolute;
  height: 100%;
  width: 100%;
}
