@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';
@import '../../sharedStyles.module.scss';

.TableCell {
  @extend .Table__cell;
  @extend .Table__cell--no-border-right;
}

.TableRow {
  @extend .Table__row;
  height: 31px;
}

.TierTableSubTable {
  @extend .TableSubTable;

  td.TierTableCell {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
