.MFA_Login_Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px;

  &__background {
    background: #f7f8fb;
    min-height: calc(100vh - 32px);
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 550px;
    margin-top: 104px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    &_code {
      margin-top: 24px;
      margin-bottom: 6px;
    }

    &_input {
      width: 256px;
      margin-bottom: 12px;

      input[type='number'] {
        -moz-appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &_error {
        border: 1px solid var(--dds-color-palette-red-33);
      }
    }

    &_error {
      margin-bottom: 24px;
    }
  }

  &__link {
    display: flex;
    position: absolute;
    padding-left: 44px;
    padding-top: 44px;
    color: var(--dds-color-palette-purple-40);

    span:hover {
      text-decoration: underline;
      color: var(--dds-color-palette-purple-60);
    }
  }

  &__footer {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    button {
      font-size: 14px;
      color: var(--dds-color-palette-purple-40);
    }

    button:hover {
      text-decoration: underline;
      color: var(--dds-color-palette-purple-60);
    }

    button:disabled {
      cursor: not-allowed;
    }

    a {
      color: var(--dds-color-palette-purple-40);
    }

    a:hover {
      text-decoration: underline;
      color: var(--dds-color-palette-purple-60);
    }
  }

  &__logo_text_black {
    display: flex;
    width: 112px;
    height: 40px;
    margin-bottom: 20px;
  }
}

.toastify {
  z-index: 999;
  padding: 8px;
  width: 320px;
  box-sizing: border-box;

  &__top_right {
    top: 1rem;
    right: 1rem;
  }
}

.toastify_content {
  z-index: 999;
  color: #ffffff;
  background: #1c2545;
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 8px;
  width: 320px;
  min-height: 48px;
  max-height: 800px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 30, 0.15),
    0 2px 8px 1px rgba(0, 0, 30, 0.08);
  overflow: hidden;
  cursor: pointer;
  border-color: #1c2545;
  align-items: center;
}
