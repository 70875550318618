@import '~scss/containers.scss';
@import '@hologram-dimension/tokens/_dimension-tokens.scss';

.planHeader {
  font: $dds-typography-font-title-medium;
}

.container {
  @extend %main-content-container;
  padding-bottom: 28px;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
}

.message {
  text-align: center;
}

.saveButton {
  align-self: flex-end;
}

.warningCallout {
  align-self: stretch;
}

.acknowledgeCheck {
  align-self: flex-end;
}

.bottomWell {
  display: flex;
  flex-flow: column nowrap;
  align-content: flex-end;
  gap: 10px;
  flex-grow: 0;
}
