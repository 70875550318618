@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~scss/panels.scss';

.panelItem {
  @extend .panel__item;
}

.listContainer {
  max-height: 460px;
  overflow: auto;
  padding-left: 72px;
}

.resultsTable {
  padding-top: 24px;
  width: 100%;

  & tr {
    padding-bottom: 4px;
  }
}

.orgCell {
  text-align: left;
  vertical-align: middle;
}

.orgCell,
.viewOrgButton {
  padding-right: 8px;
}

.viewMoreButton {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background: #f8f9fb;
  font-weight: 200;
  font-size: 18px;
  color: #091c45;
  text-align: left;
  line-height: 23px;
  margin-left: 8px;
}

.noResultsText {
  margin-left: 16px;
}

.label {
  border-bottom: 1px solid $color-gray-130;
  margin-bottom: 4px;
  padding-bottom: 4px;
}

.outerContainer {
  display: flex;
  padding: 10px;
}
