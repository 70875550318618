.active_release_flags {
  list-style: none;
  margin: 0;
  padding: 0;
  columns: 3;

  .release_flag {
    margin-left: 1em;
    text-indent: -1em;
    word-wrap: break-word;
  }
}
