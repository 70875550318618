@import '~@holokit/core/src/common/styles/_colors.scss';

.panel {
  margin-bottom: 0 !important;
}

.close {
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}
