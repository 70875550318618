@import '@hologram-dimension/tokens/_dimension-tokens.scss';
// Family
$font-stack: 'DINNextLTPro', sans-serif;

@include font-face('DINNextLTPro', 'https://cdn1.hologram.io/marketing/310614_1_0', 300); // Light
@include font-face('DINNextLTPro', 'https://cdn1.hologram.io/marketing/310614_3_0', 400); // Regular
@include font-face('DINNextLTPro', 'https://cdn2.hologram.io/marketing/310614_2_0', 500); // Medium
@include font-face('DINNextLTPro', 'https://cdn2.hologram.io/marketing/310614_0_0', 700); // Bold

a {
  text-decoration: none;
}

body {
  font-family: $font-stack;
  text-rendering: optimizeLegibility;
}

html,
body,
#__next {
  height: 100%;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}
