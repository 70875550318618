.logoBox {
  width: 10%;
}

.outsideContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
