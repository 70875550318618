@import '@hologram-dimension/tokens/_dimension-tokens.scss';

.container {
  display: flex;
  flex-direction: column;
}

.tier_card {
  width: 300px;
  margin-top: 20px;
  margin-right: 20px;
}

.input {
  margin-bottom: 10px;
}

.subHeading {
  font: $dds-typography-font-title-small;
}
