@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_motion.scss';
@import '~@holokit/core/src/common/styles/_type.scss';
@import '~@holokit/core/src/components/deepmenu/_deepmenu.scss';

.leftContainer {
  display: flex;
}

.deepMenu {
  margin: 16px 2em 0;

  & > div {
    justify-content: space-between;
  }
}

.item {
  @extend .DeepMenu__item;
}

.itemActive {
  @extend .DeepMenu__item--active;
}
