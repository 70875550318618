.panel__container {
  display: flex;
  padding-bottom: 50px;
}

.panel__item {
  flex-grow: 1;
  padding-left: 18px;
}

.form__container {
  display: flex;
  align-items: center;
}

.form__item {
  margin-right: 8px;
}

.form__results {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.form__results__item {
  display: flex;
  align-items: baseline;
  padding: 5px 0;
  margin-bottom: 5px;
}
