@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';

.container {
  display: flex;
}

.formColumn {
  flex-grow: 1;
  max-width: 300px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
}

.link {
  flex: 1 1 50%;
}

.buttonContainer {
  padding-top: 37px;
}

.error {
  display: flex;
  align-items: center;
  font-weight: 200;
  letter-spacing: 0.8px;
  margin-top: 6px;
  padding: 24px;
  font-size: 16px;
  line-height: 14px;
  max-width: 870px;
  color: $color-gray-10;
  background: #f8f9fb;
}

.errorText {
  margin-left: 8px;
}

.stateContainer {
  flex-grow: 1;
  padding-top: 12px;

  > :last-of-type {
    padding-top: 12px;
    @extend .Font__body--30;
  }
}
