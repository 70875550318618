@import '@hologram-dimension/tokens/_dimension-tokens.scss';

.container,
.carrierContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.carrierContainer {
  border-left: 1px solid $dds-color-palette-teal-40;
  padding-left: 8px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

.changedPlan {
  float: right;
  color: $dds-color-palette-red-43;
}

.fullWidthButton {
  width: 100%;
}
.subHeading {
  font: $dds-typography-font-title-small;
  padding-top: 4px;
  padding-bottom: 4px;
}
