.errorPage {
  margin: auto;
  width: 800px;
  display: flex;
  align-items: flex-end;
  flex-flow: column nowrap;
  gap: 10px;
}
.errorBanner {
  margin: 0;
}
