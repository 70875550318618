@import '~@holokit/core/src/common/styles/_colors.scss';

.modalContainer {
  padding: 16px;
}

.panel {
  margin-bottom: 0 !important;
}

.close {
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
}

.errorText {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  margin-top: 6px;
  margin-left: 4px;
  font-size: 10px;
  line-height: 14px;
  color: $color-red;
}
