.customer_info_container:after {
  content: '';
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 50%;

  th,
  td {
    padding: 10px;
    font-size: 14px;
    font-weight: normal;
    height: 20px;
    letter-spacing: 0px;
    line-height: 20px;
  }

  th {
    color: #040815;
    text-align: left;
    width: 140px;
  }

  td {
    color: rgb(84, 93, 121);
    text-align: left;
  }
}
