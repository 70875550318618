.TierColumn,
.ZoneColumn {
  width: 130px;
}

.MrcColumn,
.SmsColumn,
.OverageColumn {
  width: 80px;
}

.CarriersColumn {
  width: 275px;
}

.TableSubTable {
  border: none;

  td.TableCell,
  th,
  tr,
  tbody,
  thead {
    border: none;
  }

  tr:not(:first-child) {
    &,
    td.TierTableCell {
      border-top: 1px solid $color-gray-130;
    }
  }
}

.searchPanelWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}

.Table {
  width: 100%;
  $this: &;

  &--fixed {
    table-layout: fixed;
  }
  &--short {
    #{$this}__cell-content {
      min-height: 48px;
    }
  }
  &__header {
    &--sticky {
      #{$this}__cell--header {
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }
  }
  &__row {
    &:first-child #{$this}__cell {
      border-top: none;
    }
    &:last-child #{$this}__cell {
      border-bottom: 1px solid $color-gray-130;
    }
  }
  &__cell {
    @extend .Font__body--10;
    padding: 0;
    border-right: 1px solid $color-gray-130;
    border-top: 1px solid $color-gray-130;

    &:last-child {
      border-right: none;
    }
    &--no-border-right {
      border-right: none;
    }
    &--no-border-top {
      border-top: none;
    }
    &--header {
      @extend .Font__caption--20;
      background: $color-gray-140;
      font-weight: 400;
      text-transform: uppercase;
      text-align: left;
      padding-top: 11px;
      padding-bottom: 9px;
      border-right: none;
      border-top: none;
      position: relative;

      &:before {
        background: $color-gray-130;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
      }
      &:after {
        background: $color-gray-130;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      #{$this}__cell-content {
        min-height: auto;
        display: block;

        &--sort {
          display: flex;
          align-items: flex-end;
          line-height: 13px;
          cursor: pointer;
        }

        &__icon {
          margin-left: 2px;

          svg {
            fill: $color-gray-100;
          }

          &--sorted svg {
            fill: $color-gray-60;
          }
        }
        &:hover #{$this}__cell-content__icon svg {
          fill: $color-gray-60;
        }
      }
    }
  }
  &__cell-content {
    display: flex;
    min-height: 54px;
    align-items: center;
    padding-left: 13px;
    padding-right: 13px;

    &--centered {
      justify-content: center;
    }
    &--align-right {
      justify-content: flex-end;
    }

    &--column {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &--loading:after {
      content: '';
      display: block;
      height: 15px;
      width: 75%;
      border-radius: 4px;
      animation: 1000ms linear infinite alternate skeleton-glow;
      background: rgba(206, 217, 224, 0.2);
      pointer-events: none;
      user-select: none;
    }
    &--loading-checkbox:after {
      width: 16px;
      height: 16px;
    }
  }
  @for $i from 0 to 50 {
    @for $j from 0 to 15 {
      #{$this}__row:nth-child(#{$i})
        #{$this}__cell:nth-child(#{$j})
        #{$this}__cell-content--loading:not(#{$this}__cell-content--loading-checkbox):after {
        width: percentage(calc((random(50) + 50) / 100));
      }
    }
  }
}
