@import '~scss/panels.scss';
@import '~@holokit/core/src/common/styles/_colors.scss';

.container {
  display: flex;
  flex-direction: column;
}

.zone_card_container {
  display: flex;
}

.zone_card {
  width: 100%;
}

.input {
  margin-bottom: 10px;
}

.error {
  color: $color-red;
}
