@import '@hologram-dimension/tokens/_dimension-tokens.scss';

.groupSubHeader {
  font: $dds-typography-font-title-small;
}

.groups__container {
  display: flex;
  padding-bottom: 50px;
}

.groups__item {
  flex: 50%;
  padding-left: 50px;
  max-width: 500px;
}

.form__container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form__results {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  max-height: 500px;
  overflow-y: auto;
}

.form__results__item {
  display: flex;
  align-items: baseline;
  padding: 5px 0;
  margin-bottom: 5px;
}

.nameData {
  text-align: left;
}

.resultsTable {
  padding-top: 24px;
}

.addedOrg {
  color: $dds-color-palette-green-20;
}

.searchField {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
}
.existingWrapper {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}
