@import '~scss/panels.scss';
@import '@hologram-dimension/tokens/_dimension-tokens.scss';

.outerContainer {
  flex: 1 0 50%;
}

.groupHeader {
  font: $dds-typography-font-title-small;
}
