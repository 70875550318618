@import '~@holokit/core/src/common/styles/_colors.scss';

.warningContainer {
  background: $color-red-light;
  padding: 18px;
  margin-top: 8px;
  border-radius: 4px;
}

.panel {
  margin-bottom: 0 !important;
}

.text {
  text-align: center;
  font-size: 1.5em;
}

.buttons {
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
}
