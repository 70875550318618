@import '~scss/panels.scss';

.tier_form_container {
  display: flex;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.tier_card {
  display: flex;
  flex-direction: column;
}

.tier_card {
  width: 300px;
  margin-top: 20px;
  margin-right: 20px;
}
