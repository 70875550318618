@import '~@holokit/core/src/common/styles/_colors.scss';

.container {
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  display: flex;
  background: #545d79aa;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.innerContainer {
  background: white;
  max-width: 700px;
  width: 40%;
  border-radius: 10px;
}
