@import '~@holokit/core/src/common/styles/_colors.scss';
@import '~@holokit/core/src/common/styles/_type.scss';
@import '../../sharedStyles.module.scss';

tr:hover {
  background: #f9f9f9;
}

table {
  text-align: center;
}

td {
  vertical-align: baseline;
}

.TableCell {
  @extend .Table__cell;
  @extend .Table__cell--no-border-right;
}

.TableRow {
  @extend .Table__row;
  height: 31px;
}

.TableCellTextContentCell {
  @extend .TableCell;
}
.TableSubTable {
  border: none;

  td.TableCell,
  th,
  tr,
  tbody,
  thead {
    border: none;
  }

  td.TierTableCell {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  td.ZoneTableCell {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  tr:not(:first-child) {
    &,
    td.TierTableCell {
      border-top: 1px solid $color-gray-130;
    }
  }
}

.ListSmall {
  margin: 0;
  padding: 0;
  list-style: none;
}
