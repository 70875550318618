@import '~scss/panels.scss';

.panelContainer {
  @extend .panel__container;
}
.orgResults {
  flex: 1 0 50%;
}

.orgTableWrapper {
  max-height: 30vh;
  overflow-y: auto;
  padding-right: 16px;
}

.orgTable {
  width: 100%;
}
.orgTable tr:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}
