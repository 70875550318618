.toastContent {
  display: flex;
  justify-content: space-between;
}

.toastIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dds-color-palette-green-32);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 16px 24px;
}

.toastTextWrapper {
  display: flex;
  align-items: center;
}

.toastHeadline {
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  margin: 0;
}

.toastBody {
  margin: auto 0;
  display: flex;
}

.toastifyCloseButton {
  position: absolute;
  top: 7px;
  right: 7px;
}
