.container {
  padding: 0 18px;
}

.userInfo {
  margin: 0;
}

.userInfoTitle {
  font-weight: 600;
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }
}

.userInfoDefinition {
  margin: 0 5px 0;
}

.userRole {
  composes: userInfoDefinition;
  display: flex;
}
