@import '~scss/panels.scss';
@import '@hologram-dimension/tokens/_dimension-tokens.scss';

.panelItem {
  @extend .panel__item;
  flex-basis: 25%;
  flex-grow: 0;
  padding-left: 18px;
  padding-right: 18px;
  border-right: 1px solid $dds-color-palette-gray-98;

  &:last-of-type {
    border-right: none;
  }
}

.subHeading {
  font: $dds-typography-font-title-small;
}

.panel__container {
  @extend .panel__container;
}
